.cover_card {
  min-width: 10rem;
  min-height: 5rem;
  text-align: center;
}

.cover_card:hover {
  background-color: #eeebeb;
  transform: scale(1.05);
  transition: 0.7s;
  border-radius: 5px;
}

.cover_caption {
  cursor: pointer;
}

.cover_card:hover .cover_caption {
  color: #000;
}

.cover_thumb {
  width: 10rem !important;
  height: 15rem !important;
}

.mobile_drag {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.mobile_drag::-webkit-scrollbar {
  display: none;
}