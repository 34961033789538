/* import the necessary Bootstrap files */

// overwrite theme variables
$primary: #004b91;
// $warning: #f8991d;

.theme-navbar {
  background-color: #02562a;
  color: #fff;

  .nav-link {
    color: #fff;
  }

  .nav-link:hover {
    color: #fff;
    text-decoration: underline;
    background-color: #3eab36;
    border-radius: 2px;
  }
}

.theme-footer {
  background-color: #02562a;
  color: #fff;

  a {
    color: #fff;
  }

  a:hover {
    color: #cecece;
  }
}

.brand-logo {
  height: auto;
  width: 190px;
}
.fooLinks {
  text-decoration: none;
}

/* finally, import Bootstrap */
@import "~bootstrap/scss/bootstrap.scss";
